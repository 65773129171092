<!--县域配送新建地址-->
<template>
  <el-form
    class="form-add-new width-all"
    :model="formData"
    label-width="160px"
    @submit.native.prevent
    v-loading="loading"
  >
    <el-form-item
      label="联系人姓名"
      class="form-item"
      :rules="formRules.required"
      prop="receiveName"
    >
      <el-input v-model="formData.receiveName"></el-input>
    </el-form-item>
    <el-form-item
      label="地址"
      class="form-item"
      :rules="formRules.required"
      prop="addressInfo"
    >
      <el-col :span="10">
        <div class="location-content" @click="openMapDialog">
          <div>{{ selectAddressText }}</div>
          <i class="el-icon-map-location" style="font-size: 20px"></i>
        </div>
      </el-col>
      <el-col class="line" :span="1">门牌号</el-col>
      <el-col :span="4">
          <el-form-item
            :rules="formRules.required"
            prop="address"
          >
          <el-input v-model="formData.address"></el-input>
          </el-form-item>
      </el-col>
    </el-form-item>
    <el-form-item
      label="手机"
      class="form-item"
      :rules="formRules.required"
      prop="receivePhone"
    >
      <el-input v-model="formData.receivePhone"></el-input>
    </el-form-item>
    <el-form-item label="备注" class="form-item" prop="remark">
      <el-input v-model="formData.remark"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm" v-if="formData.id"
        >保存</el-button
      >
      <el-button type="primary" @click="submitForm" v-else>提交</el-button>
      <!-- <el-button @click="resetForm('formNameAddNew')" disabled>重置</el-button> -->
    </el-form-item>
    <el-dialog
      title="地址定位"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="50%"
      :append-to-body="true"
      :modal-append-to-body="true"
      :before-close="handleClose"
    >
      <select-address 
        ref="selectAddress"
        @success="selectAddressSuccess"> </select-address>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="closeMapDialog">确 定</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import { URL } from "../../../config";
import * as formRules from "../../../assets/js/formRules";
import selectAddress from "../../map/selectAddress";
import { isEmpty, deepsClone } from "../../../assets/js/utils";
import {
  isPostageFreeArray,
  isPostageBillingTypeArray,
} from "../../../assets/js/status";

export default {
  name: "createAddress",
  props: {
    data: Object,
    userType: {
      type: String,
      default: "supplier", // admin-总后台  supplier-商家
    },
    addressType: {
      type: Number,
      default: 1,
    },
  },
  components: {
    selectAddress,
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      formRules,
      selectAddressText: "", // 详细地址
      selectAddressInfo: "",
      formData: {
        receiveName: "", //联系人姓名
        receivePhone: "", // 手机号
        type: this.addressType == 1 ? '2' : '3', // 默认为2 县域配送地址
        lat: "", // 纬度
        lng: "", // 经度
        id: "", // 地址的id,新增不传，编辑的时候传入
        province: "", // 省份名称
        city: "", // 城市名称
        area: "", // 区域名称
        town: "", // 街道名称
        addressInfo: "", //包含省市区的完整地址
        address: "", // 详细地址
        remark: "", // 备注
      },
    };
  },
  created() {
    if (this.data.address) {
    let addressData = this.data.address;
      this.selectAddressText = addressData.addressInfo;
      this.formData = {
        receiveName: addressData.receiveName,
        receivePhone: addressData.receivePhone,
        type: this.addressType == 1 ? '2' : '3',
        lat: addressData.lat,
        lng: addressData.lng,
        id: addressData.id,
        province: addressData.province,
        city: addressData.city,
        area: addressData.area,
        town: addressData.town,
        addressInfo: addressData.addressInfo,
        address: addressData.address,
        remark: addressData.remark,
      };
    }
  },
  methods: {
    openMapDialog() {
      this.dialogVisible = true;
    },
    // 点击确认，关闭选择城市弹窗，赋值操作
    closeMapDialog() {
      if (this.selectAddressInfo == '') {
        this.$message({
          message: '请选择地址后确认',
          type: 'warning'
        });
        return
      }
      this.selectAddressText =
        this.selectAddressInfo.ad_info.province +
        this.selectAddressInfo.ad_info.city +
        this.selectAddressInfo.ad_info.district +
        this.selectAddressInfo.title;
      this.formData.province = this.selectAddressInfo.ad_info.province;
      this.formData.city = this.selectAddressInfo.ad_info.city;
      this.formData.area = this.selectAddressInfo.ad_info.district;
      this.formData.town = this.selectAddressInfo.title;
      this.formData.addressInfo =
        this.selectAddressInfo.ad_info.province +
        this.selectAddressInfo.ad_info.city +
        this.selectAddressInfo.ad_info.district +
        this.selectAddressInfo.title;
      this.formData.lat = this.selectAddressInfo.location.lat;
      this.formData.lng = this.selectAddressInfo.location.lng;
      this.dialogVisible = false;
    },
    handleClose(done) {
      done();
    },
    selectAddressSuccess(data) {
      console.log(data);
      this.selectAddressInfo = data;
    },
    submitForm() {
      let self = this;
      if (self.addressType == 1) {
        return this.axios
          .post(URL.supplierPostage.saveAddress, self.formData)
          .then((res) => {
            this.$emit("confirm");
            return Promise.resolve(res);
          })
          .catch((res) => {
            return Promise.reject(res);
          })
          .finally((res) => {});
      } else {
        return this.axios
          .post(URL.supplierPostage.saveAddress, self.formData)
          .then((res) => {
            this.$emit("confirm");
            return Promise.resolve(res);
          })
          .catch((res) => {
            return Promise.reject(res);
          })
          .finally((res) => {});
      }
    },

    // }
  },
};
</script>
<style lang="less" scoped>
@import (reference) "../../../assets/css/data";
.textarea-text {
  box-sizing: border-box;
  width: 300px;
  border: @border-width-solid solid @border-color-one;
  min-height: 100px;
}
.location-content {
  width: auto;
  height: 38px;
  border: 1px solid #dadada;
  border-radius: 4px;
  margin-right: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
#container {
  width: 100%;
  height: 100%;
}
</style>
