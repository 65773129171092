<!-- 县域配送配送模板设置 -->
<template>
  <el-form
    class="form-add-new width-all"
    :model="formData"
    label-width="160px"
    @submit.native.prevent
    v-loading="loading"
  >
    <el-form-item
      label="配送范围"
      class="form-item"
      :rules="formRules.required"
      prop="deliveryDistanceType"
    >
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="formData.deliveryDistanceType"
            placeholder="请选择"
            @change="selectDistributionType"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1.5">
          <div style="margin-right: 10px">
            {{ isAccordingDistance ? "配送半径" : "配送至" }}
          </div>
        </el-col>
        <el-col :span="5">
          <el-input-number
            v-if="isAccordingDistance"
            v-model="formData.supportDistance"
            :min="1"
            :max="50"
            label="公里"
          ></el-input-number>
          <span v-if="isAccordingDistance" style="margin-left: 10px">公里</span>
          <el-select
            v-else
            v-model="formData.supportAddressType"
            placeholder="请选择"
            @change="selectTimeType"
            style="width: 400px"
            :disabled="!data.address"
          >
            <el-option
              v-for="item in addressTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              style="width: 400px"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item label="计价方式" class="form-item">
      <div class="commitment-time">
        <el-select v-model="formData.calculateWay" placeholder="请选择">
          <el-option
            v-for="item in pricingManner"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div v-if="formData.calculateWay == 2" style="margin: 0 15px">
          下单满
        </div>
        <el-input
          v-if="formData.calculateWay == 2"
          placeholder="请输入内容"
          v-model="formData.fullAmount"
        >
          <template slot="append">元</template>
        </el-input>
        <div v-if="formData.calculateWay == 2" style="margin: 0 15px">起送</div>
      </div>
      <div
        class="commitment-time"
        style="margin-top: 20px"
        v-if="formData.calculateWay == 3"
      >
        <div style="margin-right: 15px">下单满</div>
        <el-input placeholder="请输入内容" v-model="formData.fullAmount">
          <template slot="append">元</template>
        </el-input>
        <div style="margin: 0 15px">起送,</div>
        <el-input placeholder="请输入内容" v-model="formData.startCalculateDistance">
          <template slot="append">km</template>
        </el-input>
        <div style="margin: 0 15px">内</div>
        <el-input placeholder="请输入内容" v-model="formData.startDistanceFee">
          <template slot="append">元</template>
        </el-input>
      </div>
      <div
        class="commitment-time"
        style="margin-top: 20px"
        v-if="formData.calculateWay == 3"
      >
        <div style="margin-right: 15px">每增加</div>
        <el-input placeholder="请输入内容" v-model="formData.calculateFeeStepDistance">
          <template slot="append">km</template>
        </el-input>
        <div style="margin: 0 15px">增加</div>
        <el-input placeholder="请输入内容" v-model="formData.increaseStepFee">
          <template slot="append">元</template>
        </el-input>
      </div>
    </el-form-item>
    <el-form-item label="特殊时段" class="form-item" v-show="false">
      <div class="commitment-time">
        <el-select v-model="formData.specialTimeWay" placeholder="请选择">
          <el-option
            v-for="item in specialTimeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div v-for="(item, index) in specialTimeFeeRule" :key="index">
        <div
            class="commitment-time"
            style="margin-top: 20px"
            v-if="formData.specialTimeWay == 2"
        >
            <div style="margin-right: 15px">{{'时段' + (index + 1)}}</div>
            <el-time-picker
            is-range
            v-model="item.timeValue"
            format="HH:mm"
            value-format="HH:mm"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            @change="selectTimeValue"
            >
            </el-time-picker>
            <div style="margin: 0 15px">加</div>
            <el-input placeholder="请输入内容" v-model="item.increaseAmount">
            <template slot="append">元</template>
            </el-input>
            <el-button
            v-if="index != 0"
            style="margin: 0 15px"
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="removeItem(index)"
            plain
            >
            移除</el-button
            >
        </div>
      </div>
      <div
        class="commitment-time"
        style="margin-top: 20px"
        v-if="formData.specialTimeWay == 2"
      >
        <el-button
          style="margin: 0 15px"
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="addItem"
          plain
        >
          添加特殊时段</el-button
        >
      </div>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm">提交</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { URL } from "../../../config";
import * as formRules from "../../../assets/js/formRules";
import { isEmpty, deepsClone, JSONParse } from "../../../assets/js/utils";
import {
  isPostageFreeArray,
  isPostageBillingTypeArray
} from "../../../assets/js/status";

export default {
  name: "editDistribution",
  props: {
    data: Object,
    userType: {
      type: String,
      default: "supplier" // admin-总后台  supplier-商家
    }
  },
  data() {
    return {
      formRules,
      loading: false,
      isAccordingDistance: true,
      options: [
        // 配送方式
        {
          value: 1,
          label: "按距离配送"
        },
        {
          value: 2,
          label: "全城配送"
        }
      ],
      addressTypeList: [
        // {
        //   value: 1,
        //   label: "请先配置发货地址"
        // },
        {
          value: 2,
          label: "请先配置发货地址"
        }
      ],
      pricingManner: [
        {
          value: 1,
          label: "免配送费"
        },
        {
          value: 2,
          label: "满额起送，满配送费"
        },
        {
          value: 3,
          label: "阶梯计费"
        }
      ],
      specialTimeList: [
        {
          value: 1,
          label: "无附加费用"
        },
        {
          value: 2,
          label: "增加额外配送费"
        }
      ],
      formData: {
        deliveryDistanceType: 2, // 配送距离类型，1、按距离范围配送，2、全城配送
        supportDistance: null, // 支持配送的距离 配送方式为按距离的时候
        supportAddressType: 2, // 配送支持到市还是县
        calculateWay: 1, //计价方式  1免配送费 1满额起送，满配送费 3阶梯计费
        specialTimeWay: 1, //特殊时段计费方式 1、无附加费用，2、额外增加配送费用
        calculateFeeStepDistance: '', // 阶梯计费规则：每增加多少公里，单位公里
        deliveryWay: "1", // 配送方式，1、商家配送
        fullAmount: '',
        increaseStepFee: '',
        specialTimeFeeRule: '',
        startCalculateDistance: '',
        startDistanceFee: '',
      },
      specialTimeFeeRule: [ // 特殊时段额外增加配送费用计费规则，使用json格式存储
        {
          timeValue: ["", ""],
          increaseAmount: 0,
        }
      ],
    };
  },
  created() {
    let templateData = this.data.supplierSameCityDelivery;
    let addressData = this.data.address;
    // 初始化
    if (templateData.deliveryDistanceType == 2) {
      // 全城配送
      this.isAccordingDistance = false;
    } else {
      this.isAccordingDistance = true;
    }
    if (addressData) {
      this.addressTypeList = [
          // {
          //   value: 1,
          //   label: addressData.province + addressData.city
          // },
          {
            value: 2,
            label: addressData.province + addressData.city + addressData.area
          }
      ]
    }
    this.formData = {
      ...this.formData,
      deliveryDistanceType: templateData.deliveryDistanceType, // 配送距离类型，1、按距离范围配送，2、全城配送
      supportDistance: templateData.supportDistance, // 支持配送的距离 配送方式为按距离的时
      supportAddressType: templateData.supportAddressType, // 配送支持到市还是县
      calculateWay: templateData.calculateWay, //计价方式 1、免费配送，2、满额起送，3、阶梯计费
      specialTimeWay: templateData.specialTimeWay, // 特殊时段计费方式，1、无附加费用，2、额外增加配送费用
      calculateFeeStepDistance: templateData.calculateFeeStepDistance, // 阶梯计费规则：每增加多少公里，单位公里
      deliveryWay: "1", // 配送方式，1、商家配送
      fullAmount: templateData.fullAmount, // 满额起送配置金额(阶梯计费和满额起送都用这个)
      increaseStepFee: templateData.increaseStepFee, // 阶梯计费规则：每增加多少公里,增加多少费用，单位元
      specialTimeFeeRule: templateData.specialTimeFeeRule, // 特殊时段额外增加配送费用计费规则，使用json格式存储
      startCalculateDistance: templateData.startCalculateDistance, // 阶梯计费规则：多少公里内计起送费用，单位公里	
      startDistanceFee: templateData.startDistanceFee, // 阶梯计费规则：多少公里内计起送费用金额，单位元
    };
    let list = [];
    if (JSON.parse(templateData.specialTimeFeeRule).length > 0) {
        JSON.parse(templateData.specialTimeFeeRule).map(item => {
            list.push({
                timeValue: [item.startTime, item.endTime],
                increaseAmount: item.increaseAmount,
            })
        })
        this.specialTimeFeeRule = list;
    }

  },
  methods: {
    submitForm() {
      let self = this;
      let specialTimeFeeRuleList = []; // 特殊时段额外增加配送费用-接口想要的数据
      self.specialTimeFeeRule.map((item) => {
        specialTimeFeeRuleList.push({
            endTime: item.timeValue[1],
            startTime: item.timeValue[0],
            increaseAmount: parseFloat(item.increaseAmount),
        })
      })
      let specialTimeFeeRuleStr = specialTimeFeeRuleList;

      self.formData.specialTimeFeeRule  = specialTimeFeeRuleStr
      console.log("===>", self.formData);
      return this.axios
        .post(URL.supplierPostage.editSameCityDeliveryTemplate, self.formData)
        .then(res => {
          self.$emit("confirm");
          return Promise.resolve(res);
        })
        .catch(res => {
          return Promise.reject(res);
        })
        .finally(res => {});
    },
    // 选中司时间段回调
    selectTimeValue(value) {
      if (value.length > 0) {
        this.formData.startTime = value[0];
        this.formData.endTime = value[1];
      }
    },
    selectTimeType() {},
    // 选择配送时间类型
    selectDistributionType(value) {
      if (value == 1) {
        this.isAccordingDistance = true;
      } else {
        this.isAccordingDistance = false;
      }
    },
    // 点击新增特殊时段额外配送费
    addItem() {
        this.specialTimeFeeRule.push({
          timeValue: ["", ""],
          increaseAmount: 0,
        })
    },
    // 删除一个特殊时段额外配送费用
    removeItem(index) {
        this.specialTimeFeeRule.splice(index,1)
    }
  }
};
</script>
<style lang="less" scoped>
@import (reference) "../../../assets/css/data";
.textarea-text {
  box-sizing: border-box;
  width: 300px;
  border: @border-width-solid solid @border-color-one;
  min-height: 100px;
}
.location-content {
  width: auto;
  height: 38px;
  border: 1px solid #dadada;
  border-radius: 4px;
  margin-right: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
#container {
  width: 100%;
  height: 100%;
}
.commitment-time {
  display: flex;
  align-items: center;
}
</style>
