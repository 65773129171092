<template>
  <div style="height: 450px">
    <el-autocomplete
      style="width: 100%;"
      class="inline-input"
      v-model="input"
      :fetch-suggestions="querySearch"
      :trigger-on-focus="false"
      placeholder="搜索地点"
      value-key="title"
      popper-class="title"
      @select="handleSelect"
    ></el-autocomplete>
    <div v-show="isShowMap" id="container">
      <div style="position: absolute; left: 48%; top: 77px; font-size: 40px; color: #d85858;">
        <i style="font-size: 37px; color: #d85858;"  class="el-icon-location"></i>
        <div style="font-size: 18px;color: blue;font-weight: 600;text-align: center;line-height: 0px;">+</div>
      </div>
    </div>
    <div v-show="isShowMap" class="vicinitySite-list-wrap">
      <ul class="infinite-list" v-infinite-scroll="load" infinite-scroll-immediate="false" style="padding: 0px">
        <li style="padding: 10px; border-bottom: 1px solid #dadada; list-style: none;" v-for="(item, index) in vicinitySiteList" :key="index" class="infinite-list-item">
          <el-radio v-model="radio" :label="item.title"  style="display: flex;align-items: center;" @change="switchAddress">
              <div>
                  <div>{{item.title}}</div>
                  <div class="address-content">{{item.address}}</div>
              </div>
          </el-radio>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { URL } from "../../config/url";
export default {
  name: "selectAddress",
  data() {
    return {
      radio: "1", // 用户当前选中的城市
      input: "", // 用户输入的城市
      locationData: {}, // 定位获取到的用户信息
      vicinitySiteList: [], // 附近城市区域列表
      map: '', // 地图实列
      restaurants: [], //根据关键字推荐的地址
      isShowMap: true, // 是否展示地图
      pagination: 1,
    };
  },
  mounted() {
    console.log('每一次都初始化地图')
    this.getLocation();
  },
  // beforeDestroy() {
  //   console.log('销毁地图')
  //   this.map.destroy();
  // },
  watch: {
  },
  methods: {
    //先根据IP定位
    getLocation() {
      return this.$jsonp(URL.tencentApi.location, {
        key: "ZN6BZ-SYPKU-JT3VF-BZUXO-K53ZE-5CFJE",
        output: "jsonp",
        }).then((res) => {
          this.locationData = res.result
          // 再根据定位的IP初始化地图
          this.initMap(res.result);
          // 同时获取附近的地点
          this.vicinitySite(res.result, this.pagination);
          return Promise.resolve(res);
        })
        .catch((res) => {
          return Promise.reject(res);
        });
    },
    // 初始化地图
    initMap(data) {
      let self = this
      var container = document.getElementById("container");
      var center = new TMap.LatLng(data.location.lat, data.location.lng);
      var map = new TMap.Map(container, {
        rotation: 20, //设置地图旋转角度
        pitch: 0, //设置俯仰角度（0~45）
        zoom: 16, //设置地图缩放级别
        center: center, //设置地图中心点坐标
      });
      self.map = map
      // 
      map.on("pan",function(){
      })
      map.on("dragend",function(){
          let data = {
            location: map.getCenter()
          }
          self.vicinitySiteList = []
          self.pagination = 1
          self.vicinitySite(data, self.pagination)
      })
    },
    // 下滑获取更多
    load( ) {
      this.pagination = this.pagination + 1
      this.vicinitySite(this.locationData, this.pagination)
    },
    // 根据经纬度查询附近的地点
    vicinitySite(data, pagination) {
        console.log('2.根据经纬度查询附近的地点', data, pagination, 'nearby(' + data.location.lat + ',' + data.location.lng + ',' + '1000)')
        this.$jsonp(URL.tencentApi.siteSearch,{
            boundary: 'nearby(' + data.location.lat + ',' + data.location.lng + ',' + '1000)',
            key: 'ZN6BZ-SYPKU-JT3VF-BZUXO-K53ZE-5CFJE',
            page_size: '20', 
            page_index: pagination,
            output:"jsonp"
        }).then( res => {
            this.vicinitySiteList = this.vicinitySiteList.concat(res.data);
        }).catch(res => {

        }).finally(res => {
          this.loading = false;
        });
    },
    // 用户选中了底部某一个地址
    switchAddress(tag) {
        console.log(tag)
        let self = this;
        let list = []
        let addressInfo = '';
        for (let i = 0; i < self.vicinitySiteList.length; i++) {
          if (self.vicinitySiteList[i].title == tag) {
            addressInfo = self.vicinitySiteList[i]
            list = [self.vicinitySiteList[i].location.lat, self.vicinitySiteList[i].location.lng]
            break;
          }
        }
        self.map.panTo(new TMap.LatLng(list[0],list[1]), {duration: 500})

        console.log('最终选中的消息', addressInfo)
        this.$emit('success',addressInfo)
        
        // 传给父组件
    },
    // 根据用户输入的关键字查询
    querySearch(queryString, cb) {
        let that = this;
        let data = [];
        this.isShowMap = false;
        console.log('3',URL)
        that.$jsonp(URL.tencentApi.suggestion,{
            keyword: queryString,
            region: that.locationData.ad_info.city,
            get_poi: '1',
            key: 'ZN6BZ-SYPKU-JT3VF-BZUXO-K53ZE-5CFJE',
            output:"jsonp"
        }).then( res => {
            console.log(res.data)
            cb(res.data);
        }).catch(res => {
        }).finally(res => {
        });
        // 调用 callback 返回建议列表的数据
    },
    // 选择搜索下拉框中可能的区域
    handleSelect(item) {
        this.isShowMap = true;
        this.input = ''
        console.log(item);
        this.map.panTo(new TMap.LatLng(item.location.lat,item.location.lng), {duration: 500})
        this.pagination = 1;
        let newItem = item;
        newItem.ad_info = {
          adcode: item.adcode,
          city: item.city,
          district: item.district,
          province: item.province
        }
        this.vicinitySiteList = [newItem]
        this.vicinitySite(item, this.pagination)
    }
  },
};
</script>
<style lang="less" scoped>
#container {
  margin-top: 10px;
  height: 260px;
}
.vicinitySite-list-wrap {
    height: 160px;
    overflow: auto;
}
.address-content {
    font-size: 12px;
    margin-top: 5px;
}
</style>