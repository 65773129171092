<!-- 县域配送时间设置 -->
<template>
  <el-form
    class="form-add-new width-all"
    :model="formData"
    label-width="160px"
    @submit.native.prevent
    v-loading="loading"
  >
    <el-form-item
      label="自提时间"
      class="form-item"
      :rules="formRules.required"
      prop="timeType"
    >
      <el-select v-model="formData.timeType" placeholder="请选择" @change="selectTimeType">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item
      label="自提时间"
      class="form-item"
      :rules="formRules.required"
      prop="startTime"
      v-if="isShowTimeQuantum"
    >
      <el-col :span="10">
        <el-time-picker
          is-range
          v-model="timeValue"
          format="HH:mm"
          value-format="HH:mm"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          @change="selectTimeValue"
        >
        </el-time-picker>
      </el-col>
    </el-form-item>
    <el-form-item
      v-if="false"
      label="承诺送达时间"
      class="form-item"
    >
      <div class="commitment-time">
        <el-input
          placeholder="请输入内容"
          v-model="formData.startDistance"
          style="width: 200px"
        >
          <template slot="append">km</template>
        </el-input>
        <div style="margin:0 10px">内</div>
        <el-select v-model="formData.startNeedTime" placeholder="请选择">
          <el-option
            v-for="item in startNeedTimeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div style="margin:0 10px">送达，每增加</div>
        <el-input
          placeholder="请输入内容"
          v-model="formData.calculateTimeStepDistance"
          style="width: 200px"
        >
          <template slot="append">km</template>
        </el-input>
        <div style="margin:0 10px">增加</div>
        <el-select v-model="formData.increaseStepTime" placeholder="请选择">
          <el-option
            v-for="item in startNeedTimeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </el-form-item>
    <el-form-item
      v-if="false"
      label="备货时间"
      class="form-item"
    >
      <div class="commitment-time">
        <el-time-picker
          v-model="stockingTime"
          format="H小时m分钟内"
          value-format="H:m"
          @change="selectStockingTime"
        >
        </el-time-picker>
      </div>
    </el-form-item>
    <el-form-item v-if="false" label="可预定时间" class="form-item" prop="startNeedTime">
        <el-select v-model="formData.availableTime" placeholder="请选择">
          <el-option
            v-for="item in availableTimeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm">提交</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { URL } from "../../../config";
import * as formRules from "../../../assets/js/formRules";
import { isEmpty, deepsClone } from "../../../assets/js/utils";
import {
  isPostageFreeArray,
  isPostageBillingTypeArray,
} from "../../../assets/js/status";

export default {
  name: "timing",
  props: {
    data: Object,
    userType: {
      type: String,
      default: "supplier", // admin-总后台  supplier-商家
    },
    timingType: {
        type: Number,
        default: 1
    }
  },
  data() {
    return {
      formRules,
      loading: false,
      options: [
        {
          value: "2",
          label: "自定义时间段",
        },
        {
          value: "1",
          label: "24小时配送",
        },
      ],
      startNeedTimeList: [
        {
          value: "5",
          label: "5分钟",
        },
        {
          value: "10",
          label: "10分钟",
        },
        {
          value: "15",
          label: "15分钟",
        },
        {
          value: "20",
          label: "20分钟",
        },
        {
          value: "25",
          label: "25分钟",
        },
        {
          value: "30",
          label: "30分钟",
        },
        {
          value: "35",
          label: "35分钟",
        },
        {
          value: "40",
          label: "40分钟",
        },
        {
          value: "45",
          label: "45分钟",
        },
        {
          value: "50",
          label: "50分钟",
        },
        {
          value: "55",
          label: "55分钟",
        },
        {
          value: "60",
          label: "1小时",
        },
        {
          value: "120",
          label: "2小时",
        },
        {
          value: "180",
          label: "3小时",
        },
        {
          value: "240",
          label: "4小时",
        },
        {
          value: "300",
          label: "5小时",
        },
        {
          value: "360",
          label: "6小时",
        },
        {
          value: "420",
          label: "7小时",
        },
        {
          value: "480",
          label: "8小时",
        },
        {
          value: "540",
          label: "9小时",
        },
        {
          value: "600",
          label: "10小时",
        },
        {
          value: "660",
          label: "11小时",
        },
        {
          value: "720",
          label: "12小时",
        },
      ],
      availableTimeList: [
        {
          value: "0",
          label: "当天",
        },
        {
          value: "2",
          label: "2天内",
        },
        {
          value: "3",
          label: "3天内",
        },
        {
          value: "4",
          label: "4天内",
        },
        {
          value: "5",
          label: "5天内",
        },
        {
          value: "6",
          label: "6天内",
        },
        {
          value: "7",
          label: "7天内",
        },
        {
          value: "8",
          label: "8天内",
        },
        {
          value: "9",
          label: "9天内",
        },
        {
          value: "10",
          label: "10天内",
        },
        {
          value: "11",
          label: "11天内",
        },
        {
          value: "12",
          label: "12天内",
        },
        {
          value: "13",
          label: "13天内",
        },
        {
          value: "14",
          label: "14天内",
        },
        {
          value: "15",
          label: "15天内",
        },
      ],
      isShowTimeQuantum: false,
      timeValue: ['09:00','22:00'],
      stockingTime:'1:0',
      formData: {
        startTime: '09:00', // 自提开始时间
        endTime: '22:00', // 自提结束时间
         // 时间范围
        timeType: "2", // 时间类型 1-24小时 2-自定义时间段
        startDistance: '', // 承诺送达时间规则，用于计算时间的起步距离，单位公里
        startNeedTime: '5', // 承诺送达时间规则，起步距离需要时间，单位分钟
        calculateTimeStepDistance: '', // 承诺送达时间规则，每增加多少公里，单位公里
        increaseStepTime: '5', // 承诺送达时间规则，每增加多少公里，单位公里
        availableTime: '0', // 可预订时间。0表示当天
        stockingTime: '1:0', // 备货时间
      },
    };
  },
  created() {
      let timeData = this.timingType == 1 ? this.data.supplierSameCityDelivery : this.data.supplierSelfMention;
      // 初始化
      if (timeData.timeType == 2) {
        this.isShowTimeQuantum = true 
      }
      this.timeValue = [timeData.startTime, timeData.endTime]
      this.stockingTime = this.transform1(timeData.stockingTime);
      this.formData = {
            ...this.formData,
            startTime: timeData.startTime, // 自提开始时间
            endTime: timeData.endTime, // 自提结束时间
            // 时间范围
            timeType: timeData.timeType + '', // 时间类型 1-24小时 2-自定义时间段
            startDistance: timeData.startDistance, // 承诺送达时间规则，用于计算时间的起步距离，单位公里
            startNeedTime: timeData.startNeedTime, // 承诺送达时间规则，起步距离需要时间，单位分钟
            calculateTimeStepDistance: timeData.calculateTimeStepDistance, // 承诺送达时间规则，每增加多少公里，单位公里
            increaseStepTime: timeData.increaseStepTime, // 承诺送达时间规则，每增加多少公里，单位公里
            availableTime: timeData.availableTime + '', // 可预订时间。0表示当天
            stockingTime:  timeData.stockingTime // 备货时间 
      }
  },
  methods: {
    submitForm() {
      let self = this;
      console.log("===>", self.formData);
      
      return this.axios
        .post(URL.supplierPostage[self.timingType == 1 ? 'editSameCityDeliveryTime' : 'editSelfMethodPostage'], self.formData)
        .then((res) => {
          self.$emit('confirm')
          return Promise.resolve(res);
        })
        .catch((res) => {
          return Promise.reject(res);
        })
        .finally((res) => {});
    },
    // 分钟转化小时
    transform1(data) {
        let t =  parseInt(data/60) + ':' + (data%60)
        return  t
    },
    // 小时转化分钟
    transform2(data) {
      if (data) {
        let index = data.indexOf(':');
        let t =parseInt(data.slice(0,index)) * 60 + parseInt(data.slice(0,index+1))
        return  t
      }
    },
    // 选中备货时间
    selectStockingTime(value) {
        this.formData.stockingTime = this.transform2(value);
    },
    // 选中司时间段回调
    selectTimeValue(value) {
        if (value.length > 0) {
            this.formData.startTime = value[0];
            this.formData.endTime = value[1];
        }
    },
    // 选择配送时间类型
    selectTimeType(value) {
        if (value == 1) {
            this.timeValue = ['', '']
            this.formData.startTime = ''
            this.formData.endTime = ''
            this.isShowTimeQuantum = false
        } else {
            this.isShowTimeQuantum = true
        }
    }
  },
};
</script>
<style lang="less" scoped>
@import (reference) "../../../assets/css/data";
.textarea-text {
  box-sizing: border-box;
  width: 300px;
  border: @border-width-solid solid @border-color-one;
  min-height: 100px;
}
.location-content {
  width: auto;
  height: 38px;
  border: 1px solid #dadada;
  border-radius: 4px;
  margin-right: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
#container {
  width: 100%;
  height: 100%;
}
.commitment-time {
    display: flex;
    align-items: center;
}
</style>
