<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages" v-loading="loading">
    <div v-loading.fullscreen.lock="fullscreenLoading"></div>
    <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="邮寄快递" name="first">
        <el-row>
          <el-col :span="2">
            <div class="">配送方式已开启</div>
          </el-col>
          <el-col :span="2">
            <el-switch
              v-model="mailValue"
              active-color="#07A675"
              inactive-color="#DCDFE6"
              @change="changeMailValue"
            >
            </el-switch>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="hint-text">用户可在购买商品时选择该配送方式</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="template-name">运费模板</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div @click="showAddNew">
              <el-card
                class="box-card"
                shadow="hover"
                :body-style="{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '0px'
                }"
              >
                <i class="el-icon-plus"></i>
                <div>新建运费模板</div>
              </el-card>
            </div>
          </el-col>
          <el-col v-for="(item, index) in tableData" :span="8" :key="index">
            <el-card shadow="hover" class="box-card">
              <el-row>
                <el-col :span="24">
                  <div class="title-text">{{ item.name }}</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <div class="field-conten">发货地：</div>
                </el-col>
                <el-col :span="18" class="field-conten">
                  <div>{{ JSON.parse(item.addrJson).receiveAddressInfo }}</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24" :offset="6">
                  <div class="hint-text">付款后48小时内发货</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <div class="field-conten">默认运费：</div>
                </el-col>
                <el-col :span="18" class="field-conten">
                  <div>{{ item.defaultFeeText }}</div>
                </el-col>
              </el-row>
              <el-row style="max-height: 29px;">
                <el-col :span="6">
                  <div class="field-conten">暂不发货地区：</div>
                </el-col>
                <el-col :span="18" class="field-conten">
                  <div>{{getNonDeliveryArea(item.nonDeliveryArea)}}</div>
                </el-col>
              </el-row>
              <el-row
                style="margin-top: 70px; display: flex; align-items: center"
              >
                <el-col :span="6">
                  <el-radio v-model="defaultTemplate" :label="item.id" @change="changeDefaultTemplate"
                    >设为默认</el-radio
                  >
                </el-col>
                <el-col :span="2" :offset="16">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    @click="getPostageInfo(item)"
                    circle
                  ></el-button>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </el-tab-pane>
      <!-- 第一期下面两个方式  先不要 -->
      <!-- <el-tab-pane label="县域配送" name="second">
        <el-row>
          <el-col :span="2">
            <div class="">配送方式已开启</div>
          </el-col>
          <el-col :span="2">
            <el-switch
              v-model="cityValue"
              active-color="#07A675"
              inactive-color="#DCDFE6"
              @change="changeCityValue"
            >
            </el-switch>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="hint-text">用户可在购买商品时选择该配送方式</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="template-name">发货地址</div>
          </el-col>
          <el-col :span="8">
            <div class="template-name">时间设置</div>
          </el-col>
          <el-col :span="8">
            <div class="template-name">配送模板</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div v-if="sameCityContent.address">
              <el-card shadow="hover" class="box-card">
                <el-row>
                  <el-col :span="24">
                    <div class="title-text">
                      {{ sameCityContent.address.receiveName }}
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="6">
                    <div class="field-conten">联系方式：</div>
                  </el-col>
                  <el-col :span="18" class="field-conten">
                    <div>{{ sameCityContent.address.receivePhone }}</div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="6">
                    <div class="field-conten">详细地址：</div>
                  </el-col>
                  <el-col :span="18" class="field-conten">
                    <div>
                      {{
                        sameCityContent.address.addressInfo +
                          sameCityContent.address.address
                      }}
                    </div>
                  </el-col>
                </el-row>
                <el-row
                  style="margin-top: 130px; display: flex; align-items: center"
                >
                  <el-col :span="2" :offset="22">
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      @click="showAddAddressDialogs(1)"
                      circle
                    ></el-button>
                  </el-col>
                </el-row>
              </el-card>
            </div>
            <div v-else @click="showAddAddressDialogs(1)">
              <el-card
                class="box-card"
                shadow="hover"
                :body-style="{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '0px'
                }"
              >
                <i class="el-icon-plus"></i>
                <div>新建地址</div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="8">
            <el-card
              shadow="hover"
              class="box-card"
              v-if="sameCityContent.supplierSameCityDelivery"
            >
              <el-row>
                <el-col :span="6">
                  <div class="field-conten">配送时间：</div>
                </el-col>
                <el-col v-if="sameCityContent.supplierSameCityDelivery.timeType == 2" :span="2" class="field-conten">
                  <div>
                    {{
                      sameCityContent.supplierSameCityDelivery.startTime || ""
                    }}
                  </div>
                </el-col>
                <el-col v-if="sameCityContent.supplierSameCityDelivery.timeType == 2" :span="1" class="field-conten">
                  <div>-</div>
                </el-col>
                <el-col v-if="sameCityContent.supplierSameCityDelivery.timeType == 2" :span="6" class="field-conten">
                  <div>
                    {{ sameCityContent.supplierSameCityDelivery.endTime || "" }}
                  </div>
                </el-col>
                <el-col v-if="sameCityContent.supplierSameCityDelivery.timeType == 1" :span="6" class="field-conten">
                  <div>24小时配送</div>
                </el-col>
              </el-row> -->
              <!-- <el-row>
                <el-col :span="6">
                  <div class="field-conten">承诺送达时间：</div>
                </el-col>
                <el-col :span="18" class="field-conten">
                  <div>
                    {{
                      sameCityContent.supplierSameCityDelivery.startDistance +
                        "公里" +
                        sameCityContent.supplierSameCityDelivery.startNeedTime +
                        "分钟内送达，每增加" +
                        sameCityContent.supplierSameCityDelivery
                          .calculateTimeStepDistance +
                        "公里增加" +
                        sameCityContent.supplierSameCityDelivery
                          .increaseStepTime +
                        "分钟"
                    }}
                  </div>
                </el-col>
              </el-row> -->
              <!-- <el-row>
                <el-col :span="6">
                  <div class="field-conten">可预订时间：</div>
                </el-col>
                <el-col :span="18" class="field-conten">
                  <div>
                    {{
                      sameCityContent.supplierSameCityDelivery.availableTime +
                        "天内"
                    }}
                  </div>
                </el-col>
              </el-row> -->
              <!-- <el-row
                style="margin-top: 190px; display: flex; align-items: center"
              >
                <el-col :span="2" :offset="22">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    @click="showTimingDialogs(1)"
                    circle
                  ></el-button>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card
              shadow="hover"
              class="box-card"
              v-if="sameCityContent.supplierSameCityDelivery"
            >
              <el-row>
                <el-col :span="6">
                  <div class="field-conten">配送方：</div>
                </el-col>
                <el-col :span="18" class="field-conten">
                  <div>商家自送</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <div class="field-conten">配送范围：</div>
                </el-col>
                <el-col
                  v-if="
                    sameCityContent.supplierSameCityDelivery
                      .deliveryDistanceType == 1
                  "
                  :span="18"
                  class="field-conten"
                >
                  <div>
                    {{
                      sameCityContent.supplierSameCityDelivery.supportDistance +
                        "公里以内"
                    }}
                  </div>
                </el-col>
                <el-col v-else :span="18" class="field-conten">
                  <div>
                    {{getDeliveryArea}}
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <div class="field-conten">计价方式：</div>
                </el-col>
                <el-col :span="18" class="field-conten">
                  <div>{{ calculateWay }}</div>
                </el-col>
              </el-row>
              <el-row v-show="false">
                <el-col :span="6">
                  <div class="field-conten">特殊时段：</div>
                </el-col>
                <el-col :span="18" class="field-conten">
                  <div>{{ specialHoursText }}</div>
                </el-col>
              </el-row>
              <el-row
                style="margin-top: 130px; display: flex; align-items: center"
              >
                <el-col :span="2" :offset="22">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    @click="showDistributionDialogs"
                    circle
                  ></el-button>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="到店自提" name="third">
        <el-row>
          <el-col :span="2">
            <div class="">配送方式已开启</div>
          </el-col>
          <el-col :span="2">
            <el-switch
              v-model="pickUpValue"
              active-color="#07A675"
              inactive-color="#DCDFE6"
              @change="changePickUpValue"
            >
            </el-switch>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div class="hint-text">用户可在购买商品时选择该配送方式</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="template-name">取货地址</div>
          </el-col>
          <el-col :span="8">
            <div class="template-name">时间设置</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <div v-if="selfMentionContent.address">
              <el-card shadow="hover" class="box-card">
                <el-row>
                  <el-col :span="24">
                    <div class="title-text">
                      {{ selfMentionContent.address.receiveName }}
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="6">
                    <div class="field-conten">联系方式：</div>
                  </el-col>
                  <el-col :span="18" class="field-conten">
                    <div>{{ selfMentionContent.address.receivePhone }}</div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="6">
                    <div class="field-conten">详细地址：</div>
                  </el-col>
                  <el-col :span="18" class="field-conten">
                    <div>
                      {{
                        selfMentionContent.address.addressInfo +
                          selfMentionContent.address.address
                      }}
                    </div>
                  </el-col>
                </el-row>
                <el-row
                  style="margin-top: 130px; display: flex; align-items: center"
                >
                  <el-col :span="2" :offset="22">
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      @click="showAddAddressDialogs(2)"
                      circle
                    ></el-button>
                  </el-col>
                </el-row>
              </el-card>
            </div>
            <div v-else @click="showAddAddressDialogs(2)">
              <el-card
                class="box-card"
                shadow="hover"
                :body-style="{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '0px'
                }"
              >
                <i class="el-icon-plus"></i>
                <div>新建地址</div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="8">
            <el-card
              shadow="hover"
              class="box-card"
              v-if="selfMentionContent.supplierSelfMention"
            >
              <el-row>
                <el-col :span="6">
                  <div class="field-conten">自提时间：</div>
                </el-col>
                <el-col :span="2" class="field-conten" v-if="selfMentionContent.supplierSelfMention.timeType == 2">
                  <div>
                    {{ selfMentionContent.supplierSelfMention.startTime || "" }}
                  </div>
                </el-col>
                <el-col :span="1" class="field-conten" v-if="selfMentionContent.supplierSelfMention.timeType == 2">
                  <div>-</div>
                </el-col>
                <el-col :span="6" class="field-conten" v-if="selfMentionContent.supplierSelfMention.timeType == 2">
                  <div>
                    {{ selfMentionContent.supplierSelfMention.endTime || "" }}
                  </div>
                </el-col>
                <el-col :span="6" class="field-conten" v-if="selfMentionContent.supplierSelfMention.timeType == 1">
                  <div>
                   24小时
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="false">
                <el-col :span="6">
                  <div class="field-conten">备货时间：</div>
                </el-col>
                <el-col :span="18" class="field-conten">
                  <div>
                    {{
                      selfMentionContent.supplierSelfMention.stockingTime +
                        "分钟内"
                    }}
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="false">
                <el-col :span="6">
                  <div class="field-conten">可预订时间：</div>
                </el-col>
                <el-col :span="18" class="field-conten">
                  <div>
                    {{
                      selfMentionContent.supplierSelfMention.availableTime +
                        "天内"
                    }}
                  </div>
                </el-col>
              </el-row>
              <el-row
                style="margin-top: 190px; display: flex; align-items: center"
              >
                <el-col :span="2" :offset="22">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    @click="showTimingDialogs(2)"
                    circle
                  ></el-button>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </el-tab-pane> -->
    </el-tabs>
    <!-- 0-编辑、新建模板弹窗 -->
    <el-drawer
      class="drawer-scroll-y"
      title="运费模板"
      ref="drawerNameEditGoods"
      :withHeader="true"
      :visible.sync="visibleAddNew"
      :append-to-body="true"
      @closed="hideAddNew"
      size="calc(100% - 300px)"
    >
      <div class="drawer-scroll-y-box">
        <edit-postage
          :data="editGoodsData"
          :table-data="tableData"
          :type="editGoodsType"
          @success="editGoodsSuccess"
        ></edit-postage>
      </div>
    </el-drawer>
    <!-- 1-编辑、新建发货地址弹窗 -->
    <el-drawer
      class="drawer-scroll-y"
      title="新建县域配送地址"
      :withHeader="true"
      :visible.sync="isShowAddAddressDialogs"
      :append-to-body="true"
      @closed="hideAddAddressDialogs"
      size="calc(100% - 300px)"
    >
      <div class="drawer-scroll-y-box">
        <create-address
          :addressType="addressType"
          :data="sameCityContent"
          @confirm="editAddressDialogs"
        ></create-address>
      </div>
    </el-drawer>
    <!-- 2-时间设置弹窗 -->
    <el-drawer
      class="drawer-scroll-y"
      title="时间设置"
      :withHeader="true"
      :visible.sync="isShowTimingDialogs"
      :append-to-body="true"
      @closed="hideTimingDialogs"
      size="calc(100% - 300px)"
    >
      <div class="drawer-scroll-y-box">
        <timing
          :data="sameCityContent"
          :timingType="timingType"
          @confirm="editTimingDialogs"
        ></timing>
      </div>
    </el-drawer>
    <!-- 3-配送模板设置弹窗 -->
    <el-drawer
      class="drawer-scroll-y"
      title="配送模板设置"
      :withHeader="true"
      :visible.sync="isShowDistributionDialogs"
      :append-to-body="true"
      @closed="hideDistributionDialogs"
      size="calc(100% - 300px)"
    >
      <div class="drawer-scroll-y-box">
        <editDistribution
          :data="sameCityContent"
          @confirm="editDistributionDialogs"
        ></editDistribution>
      </div>
    </el-drawer>
    <!-- 4-自提地址弹窗 -->
    <el-drawer
      class="drawer-scroll-y"
      title="新建自提地址"
      :withHeader="true"
      :visible.sync="isShowAddAddressDialogs1"
      :append-to-body="true"
      @closed="hideAddAddressDialogs"
      size="calc(100% - 300px)"
    >
      <div class="drawer-scroll-y-box">
        <create-address
          :addressType="addressType"
          :data="selfMentionContent"
          @confirm="editAddressDialogs"
        ></create-address>
      </div>
    </el-drawer>
    <!-- 5-自提时间设置弹窗 -->
    <el-drawer
      class="drawer-scroll-y"
      title="时间设置"
      :withHeader="true"
      :visible.sync="isShowTimingDialogs1"
      :append-to-body="true"
      @closed="hideTimingDialogs"
      size="calc(100% - 300px)"
    >
      <div class="drawer-scroll-y-box">
        <timing
          :data="selfMentionContent"
          :timingType="timingType"
          @confirm="editTimingDialogs"
        ></timing>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { baseURL, URL, URL_MINOR } from "../../../config";
import {
  isEmpty,
  objectRemoveEmpty,
  disposeSearchData,
  parseTimeObject
} from "../../../assets/js/utils";
import editPostage from "../../../components/supplier/editPostage/editPostage";
import createAddress from "../../../components/supplier/editPostage/createAddress";
import timing from "../../../components/supplier/editPostage/timing";
import editDistribution from "../../../components/supplier/editPostage/editDistribution";
import { isPostageFree } from "../../../assets/js/status";

export default {
  name: "supplierPostage",
  components: {
    editPostage,
    createAddress,
    timing,
    editDistribution
  },
  data() {
    return {
      loading: false,
      fullscreenLoading:false,
      /* 数据表格 */
      isPostageFree,
      searchKeyType: [{ id: "name", name: "模板名称", disabled: true }],
      classifyList: [],
      classifyValue: [""],
      searchData: {
        searchType: "name",
        searchValue: ""
      },
      tableData: [], // 邮寄快递数据列表
      sameCityContent: {}, //县域配送数据
      selfMentionContent: {}, // 自提板块数据
      visibleAddNew: false, // 是否展示邮寄快递新建运费模板弹窗
      isShowAddAddressDialogs: false, // 是否展示县域配送新建地址发货弹窗
      isShowAddAddressDialogs1: false, // 是否展示自提新建地址发货弹窗
      isShowTimingDialogs: false, // 是否展示县域配送时间设置弹窗
      isShowTimingDialogs1: false, // 是否展示自提时间设置弹窗
      isShowDistributionDialogs: false, // 是否展示配送模板弹窗
      addressType: 1, // 1.县域配送新建地址 2.自提新建地址
      timingType: 1, // 1.县域配送时间设置 2.自提时间设置
      editGoodsData: {},
      editGoodsType: 1,
      activeName: "first", // 当前配送方式
      mailValue: true, // 是否启用邮寄
      cityValue: false, // 是否启用县域
      pickUpValue: false, // 是否启用自提
      defaultTemplate: ''
    };
  },
  created() {
    this.getList(); // 邮寄
    this.getSameCityContent(); // 县域
    this.getSelfMentionContent(); // 自提
    this.getSupplierDelivery(); // 打开的配送方式
  },
  computed: {
    calculateWay() {
      let calculateWay = "";
      if (this.sameCityContent.supplierSameCityDelivery.calculateWay == 1) {
        calculateWay = "免费配送";
      } else if (
        this.sameCityContent.supplierSameCityDelivery.calculateWay == 2
      ) {
        calculateWay = "满额起送";
      } else {
        calculateWay = `阶梯计费-下单满${this.sameCityContent.supplierSameCityDelivery.fullAmount}元起送, ${this.sameCityContent.supplierSameCityDelivery.startCalculateDistance}公里内${this.sameCityContent.supplierSameCityDelivery.startDistanceFee}元, 每增加${this.sameCityContent.supplierSameCityDelivery.calculateFeeStepDistance}公里加${this.sameCityContent.supplierSameCityDelivery.increaseStepFee}元`;
      }
      return calculateWay;
    },
    specialHoursText() {
      let text = "";
      if (this.sameCityContent.supplierSameCityDelivery.specialTimeWay == 1) {
        text = "无特殊时段费用";
      } else {
        text = "";
        JSON.parse(
          this.sameCityContent.supplierSameCityDelivery.specialTimeFeeRule
        ).forEach(item => {
          text =
            text +
            `${item.startTime} - ${item.endTime} 加 ${item.increaseAmount}元 ;`;
        });
      }
      return text;
    },
    // 县域配送展示数据
    getDeliveryArea() {
      let text = '';
      if (this.sameCityContent.address) {
        if (this.sameCityContent.isOpenSameCity == 2) {
          text = '县域配送'
        } else {
          if (this.sameCityContent.supplierSameCityDelivery.supportAddressType == 1) {
            text = this.sameCityContent.address.province + this.sameCityContent.address.city
          } else {
            text = this.sameCityContent.address.province + this.sameCityContent.address.city + this.sameCityContent.address.area
          }
        }
      } else {
         text = '暂未设置县域配送发货地'
      }
      return text
    }
  },
  methods: {
    /**
     * 拼接搜索参数
     */
    jointSearchData(type) {
      let data = {};
      data = Object.assign(data, this.searchData);
      disposeSearchData(data);
      return data;
    },
    /**
     * 查询邮寄快递列表数据
     */
    getList() {
      return new Promise((resolve, reject) => {
        let data = this.jointSearchData();
        this.loading = true;
        this.fullscreenLoading = true
        this.axios
          .get(URL.supplierPostage.list, { params: data })
          .then(res => {
            res.data.forEach(res => {
              parseTimeObject(res);
              if (res.isDefault == 1) {
                this.defaultTemplate = res.id 
              } 
            });
            this.tableData = res.data;
            resolve(res);
          })
          .catch(res => {
            this.tableData = [];
            // reject(res);
          })
          .finally(res => {
            this.fullscreenLoading = false
            this.loading = false;
          });
      });
    },
    // 渲染暂不发货地区
    getNonDeliveryArea(val) {
      let list = JSON.parse(val);
      let text = '未配置暂不发货地区'
      if ((list || []).length > 0) {
        text = ''
        list.forEach(item1=>{
          item1.cityList.forEach(item2=>{
            item2.areaList.forEach(item3=>{
              text = text  + item1.province + item2.city + item3 + '、'
            })
          })
        })
        text = text.slice(0, text.length-1)
      }
      return text
    },
    // 获取县域配送板块数据
    getSameCityContent() {
      return new Promise((resolve, reject) => {
        let data = this.jointSearchData();
        this.loading = true;
        this.axios
          .get(URL.supplierPostage.getSameCityContent, { params: data })
          .then(res => {
            this.sameCityContent = res.data;
            resolve(res);
          })
          .catch(res => {
            this.sameCityContent = {};
            // reject(res);
          })
          .finally(res => {
            this.loading = false;
          });
      });
    },
    // 获取自提板块数据
    getSelfMentionContent() {
      return new Promise((resolve, reject) => {
        let data = this.jointSearchData();
        this.loading = true;
        this.axios
          .get(URL.supplierPostage.getSelfMentionContent, { params: data })
          .then(res => {
            this.selfMentionContent = res.data;
            resolve(res);
          })
          .catch(res => {
            this.selfMentionContent = {};
            // reject(res);
          })
          .finally(res => {
            this.loading = false;
          });
      });
    },
    // 打开邮寄快递-新建模板弹窗
    showAddNew(data, type = 1) {
      if (typeof data === "object" && type === 0) {
        this.editGoodsData = data;
      } else {
        this.editGoodsData = {};
      }
      this.visibleAddNew = true;
    },
    // 打开县域配送-新建地址弹窗
    showAddAddressDialogs(type) {
      this.addressType = type;
      if (type == 1) {
        this.isShowAddAddressDialogs = true;
      } else {
        this.isShowAddAddressDialogs1 = true;
      }
    },
    // 打开县域配送-时间设置弹窗
    showTimingDialogs(type) {
      this.timingType = type;
      if (type == 1) {
        this.isShowTimingDialogs = true;
      } else {
        this.isShowTimingDialogs1 = true;
      }
    },
    // 打开县域配送-编辑模板弹窗
    showDistributionDialogs() {
      this.isShowDistributionDialogs = true;
    },
    // 关闭邮寄快递新建模板弹窗
    hideAddNew() {
      this.visibleAddNew = false;
    },
    // 关闭新建地址发货弹窗
    hideAddAddressDialogs() {
      if (this.addressType == 1) {
        this.isShowAddAddressDialogs = false;
      } else {
        this.isShowAddAddressDialogs1 = false;
      }
    },
    // 关闭时间设置弹窗
    hideTimingDialogs() {
      if (this.timingType == 1) {
        this.isShowTimingDialogs = false;
      } else {
        this.isShowTimingDialogs1 = false;
      }
    },
    // 关闭配送模板设置弹窗
    hideDistributionDialogs() {
      this.isShowDistributionDialogs = false;
    },
    // 关闭邮寄地址弹窗-并刷新
    editAddressDialogs() {
      if (this.addressType == 1) {
        this.getSameCityContent();
      } else {
        this.getSelfMentionContent();
      }
      this.hideAddAddressDialogs();
    },
    // 关闭配送时间设置弹窗-并刷新
    editTimingDialogs() {
      if (this.timingType == 1) {
        this.getSameCityContent();
      } else {
        this.getSelfMentionContent();
      }
      this.hideTimingDialogs();
    },
    // 关闭县域配送配送模板设置弹窗-并刷新
    editDistributionDialogs() {
      this.getSameCityContent();
      this.hideDistributionDialogs();
    },
    // 关闭编辑邮寄快递运费模板弹窗-并刷新
    editGoodsSuccess(res) {
      this.getList();
      this.hideAddNew();
    },
    /**
     * 获取运费模板详情
     */
    getPostageInfo(row) {
      let data = {
        id: row.id
      };
      this.loading = true;
      this.axios
        .get(URL.supplierPostage.details, { params: data })
        .then(res => {
          this.showAddNew(res.data, 0);
        })
        .catch(res => {})
        .finally(res => {
          this.loading = false;
        });
    },
    //删除运费模板
    deletePostageItem(row) {
      let data = {
        id: row.id
      };
      this.$confirm(
        "删除后使用该模板的商品将会【包邮】, 是否继续?",
        "确认删除",
        {
          confirmButtonText: "删除",
          type: "error"
        }
      )
        .then(res => {
          this.loading = true;
          return this.axios.post(
            URL.supplierPostage.deleteSupplierPostage,
            this.qs.stringify(data)
          );
        })
        .then(res => {
          this.getList();
        })
        .catch(res => {})
        .finally(res => {
          this.loading = false;
        });
    },
    // 获取打开的配送方式
    getSupplierDelivery() {
      let self = this;
      self.axios
        .get(URL.supplierPostage.getSupplierDelivery)
        .then(res => {
          self.mailValue = res.data.isOpenPostage == 1;
          self.cityValue = res.data.isOpenSameCity == 1;
          self.pickUpValue = res.data.isOpenSelfMention == 1;
          resolve(res);
        })
        .catch(res => {})
        .finally(res => {});
    },
    // 切换Tab
    handleClick(tab, event) {
      console.log(tab.index, event);
    },
    // 关闭方式警告
    modifyPrompt(val,text,num,key) {
      this.$confirm(`关闭后可能会导致只支持${text}方式的商品无法购买，请谨慎操作！！！`, '提示', {
        confirmButtonText: '确定关闭',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.changeDistributionType(num, val == true ? 0 : 1);
      }).catch(() => {   
        this[key] = true;
      });
    },
    //打开、关闭邮寄
    changeMailValue(val) {
      if (!val) {
        if (this.cityValue || this.pickUpValue) {
          this.modifyPrompt(val,'邮寄快递',0,'mailValue')
        } else {
          this.$message({
            message: "必须保证有一个配送方式是开启的",
            type: "warning"
          });
          this.mailValue = true;
          return;
        }
      } else {
        this.changeDistributionType(0, val == true ? 0 : 1);
      }
    },
    //打开、关闭县域
    changeCityValue(val) {
      if (!val) {
        if (this.mailValue || this.pickUpValue) {
          this.modifyPrompt(val,'县域配送',1,'cityValue')
        } else {
          this.$message({
            message: "必须保证有一个配送方式是开启的",
            type: "warning"
          });
          this.cityValue = true;
          return;
        }
      } else {
        if(!this.sameCityContent.address) {
          this.$message({
            message: "请先创建发货地址相关信息后再打开次配送方式",
            type: "warning"
          });
          this.cityValue = false;
          return
        }
        this.changeDistributionType(1, val == true ? 0 : 1);
      }
    },
    //打开、关闭自提
    changePickUpValue(val) {
      if (!val) {
        if (this.cityValue || this.mailValue) {
         this.modifyPrompt(val,'到店自提',2,'pickUpValue')
        } else {
          this.$message({
            message: "必须保证有一个配送方式是开启的",
            type: "warning"
          });
          this.pickUpValue = true;
          return;
        }
      } else {
        if(!this.selfMentionContent.address) {
          this.$message({
            message: "请先创建自提地址相关信息后再打开次配送方式",
            type: "warning"
          });
          this.pickUpValue = false;
          return
        }
        this.changeDistributionType(2, val == true ? 0 : 1);
      }
    },
    // 打开或关闭某个配送方式
    changeDistributionType(index, is) {
      let apiStrList = ["Postage", "SameCityPostage", "SelfMethodPostage"];
      let openOrClose = ["open", "close"];
      let self = this;
      self.axios
        .post(URL.supplierPostage[openOrClose[is] + apiStrList[index]])
        .then(res => {
          self.$message({
            message: "成功",
            type: "success"
          });
          resolve(res);
        })
        .catch(res => {})
        .finally(res => {});
    },
    // 设为默认模板
    changeDefaultTemplate(val) {
      let self = this;
      self.axios
        .post(URL.supplierPostage.setDefaultPostage, this.qs.stringify({id:val}))
        .then(res => {
          self.$message({
            message: "设置默认成功",
            type: "success"
          });
          resolve(res);
        })
        .catch(res => {})
        .finally(res => {});
      }
  },
  watch: {}
};
</script>

<style lang="less">
.form-item {
  .el-input {
    max-width: 300px;
  }
}
.el-tabs__item {
  font-size: 16px;
}
.box-card {
  margin-top: 20px;
  height: 300px;
}
</style>
<style lang="less" scoped>
.goods-cover {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.hint-text {
  font-size: 12px;
  color: #777;
  padding-top: 10px;
}
.template-name {
  margin-top: 20px;
}
.add-template-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.title-text {
  padding-bottom: 10px;
}
.field-conten {
  font-size: 14px;
  padding-top: 10px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
</style>
